#mainh1{
    text-align: center;
    margin: 15px 0px;
    font-size: 2.8em;
}

// #region cCard
.cCard{
    @include flex(column, center, center, nowrap);
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    background-color: white;
    position: relative;
    border-radius: 10px;
    border: 1px solid rgba(0, 0, 0, 0.199);
    font-family: $secondaryFont;
    text-align: center;
    transform: rotate(0);
    img{
        padding: 10px;
        border-radius: 20px;
        width: 95%;
    }
    a{
        margin-bottom: 5px;
    }
    h2{
        font-family: $secondaryFont;
        font-size: 1.2em;
        cursor: default;
    }
    input{
        opacity: 0;
    }
    .btnInfo{
        position: absolute;
        opacity: 0;
        top: 0;
        right: 0;
        margin: 5px;
        transition: opacity 0.3s;
    }
}
.cCard:hover .btnInfo{
    opacity: 1;
}

.orderCard{
    @extend .cCard;
    background-color: #eed9b2;
    border: 1px solid map-get($map: $paleta, $key: evenDarkerSecondary);
    padding: 15px;
    margin: 0px 2px;
}
// #endregion

// #region buttn
.buttn{
    background-color: map-get($map: $paleta, $key: brownish);
    border: 0.5px solid black;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.103);
    font-family: $mainFont;
    border-radius: 5px;
    transition: 0.3s;
    padding: 3px;
    font-size: 1.2em;
}
.buttn:disabled{
    opacity: 50%;
}

.buttn:hover{
    background-color: map-get($map: $paleta, $key: lighterSecondary);
    color: black;
}
// #endregion

// #region franjaTop
#franjaTop{
    img{
        width: 130px;
    }
    display: flex;
    justify-content: center;
    padding: 15px 0px;
    background-size: cover;
}
// #endregion

// #region botonesIndex
#botonesIndex{
    background-color: map-get($map: $paleta, $key: secondary);
    @include flex(row, space-around, center, nowrap);
    min-height: 20vh;
    margin: 10px 0px;
    h2{
        color: white;
        margin: 0px;
    }
    #b1{
        background-color: map-get($map: $paleta, $key: secondary);
    }
    #b2{
        background-color: #581c4172;
    }
    #b3{
        background-color: map-get($map: $paleta, $key: evenDarkerSecondary);
    }
    #b1, #b2, #b3{
        width: 100%;
        height: 20vh;
        transition: 0.3s;
        @include flex(row, center, center, nowrap);
    }
    #b1:hover, #b2:hover, #b3:hover{
        background-color: #88466f;
    }
}
// #endregion

// #region navbar&offcanvas
#offCanvas{
    background-color: map-get($map: $paleta, $key: secondary) !important;
    a, div{
        color: white;
        font-size: large;
        text-decoration: none;
    }
    img{
        width: 1.9em;
        cursor: pointer;
    }

    .offcanvasItems{
        a{
            margin: 10px 0px;
        }
    }
}

.userNavbar{
    background-color: map-get($map: $paleta, $key: darkerSecondary) !important;
}

.navbar{
    background-color: map-get($map: $paleta, $key: secondary);
    #cartLogo{
        width: 1.9em;
        cursor: pointer;
    }
    padding: 0px;

    .navItems{
        @include flex(row, flex-start, center, nowrap);
        a{
            margin: 0px 10px;
            color: white;
            font-size: large;
            text-decoration: none;
            .active{
                color: white;
            }
        }
    }

    button{
        background: none;
        color: inherit;
        border: none;
        padding: 0;
        font: inherit;
        cursor: pointer;
        outline: inherit;
    }
}
// #endregion

// #region boxcarousel
.cell{
    @include flex(row, center, center, nowrap);
    width: 50%;
    margin: 0px 5px;
    img{
        width: 50%;
        margin: 0px 3px;
    }
}
#boxCarousel{
    .carousel-control-prev-icon {
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3e%3cpath d='M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z'/%3e%3c/svg%3e") !important;
    }
    
    .carousel-control-next-icon {
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3e%3cpath d='M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e") !important;
    }

    .carousel-inner{
        width: 100%;
        margin-left: 0px;
        margin-right: 0px;
        padding: 0px 5em;
    }
}
// #endregion

// #region containerMap
#containerMap{
    @include flex(column, center, center, nowrap);
    // background: url("http://localhost:3000/img/test.webp");
    background: rgb(240,230,199);
    background: linear-gradient(180deg, rgba(240,230,199,1) 0%, rgba(105,34,78,1) 83%);
    padding: 15px;
    margin: 5px 0px 0px 0px;

    #cmColumn{
        @include flex(row, center, center, nowrap);
        img{
            width: 85%;
        }
        div{
            @include flex(row, center, center, nowrap);
            height: 100%;
        }
    }
    
    #cmIframe{
        margin-top: 15px;
        width: 100%;
        text-align: center;
        iframe{
            width: 95%;
        }
    }
}

// #endregion

// #region filteredBox
#filtros{
    padding: 10px;
    h2{
        color: white !important;
        font-family: $mainFont;
    }
    h3{
        font-size: 2em;
    }
    ul{
        list-style: none;
        margin-top: 0;
        padding-left: 10px;
        
        li{
            margin: 8px 0px;
            font-size: 1.1em;
        }
        input{
            margin-right: 5px;
        }
    }
    button{
        margin-bottom: 15px;
    }

    .accordion-button:not(.collapsed), .accordion-button{
        color: black;
        background-color: map-get($map: $paleta, $key: darkerSecondary);
    }
    .accordion-button::after {
        display: none !important;
    }

}
// #endregion

.swal2-popup{
    @include flex(column, center, center, nowrap);
    z-index: 9999999999;
    h2{
        font-weight: 600;
        font-size: 1.3em;
        text-align: center;
    }
    h3{
        font-weight: 400;
        font-size: 1.1em;
        text-align: center;
        padding: 0px 50px;
        color:black;
    }
    .info-image{
        width: 100%;
        padding: 0px;
    }
    img{
        padding: 15px;
        width: 85%;
    }
    .productList{
        @include flex(column, center, center, nowrap)
    }
    
}

.bgText{
    background-color: map-get($map: $paleta, $key: secondary) !important;
}

// #region preLoader
.preLoader{
    background-color: map-get($map: $paleta, $key: main);
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    @include flex(row, center, center, nowrap);
    z-index: 9999;
}

.preLoaderTransparent{
    @extend .preLoader;
    background-color: #f0e6c7ef !important;
}

.preLoaderCard{
    @extend .preLoader;
    @include flex(column, center, center, nowrap);
    position: absolute;
    height: 40vh;
}
// #endregion

// #region counter
.counter {
    text-align: center;
    .counter-button {
        padding: 0px 10px;
        border: 0.5px solid black;
        background-color: map-get($map: $paleta, $key: brownish);
        color: black;
        font-size: 25px;
        cursor: pointer;
    }
    .counter-button-left {
    border-radius: 5px 0 0 5px;
    }
    .counter-button-right {
    border-radius: 0 5px 5px 0;
    }
    .counter-value {
    margin: 0px 10px;
    font-size: 25px;
    }
  }
// #endregion

// #region customBoxes
#customBoxContainer{
    margin: 15px 120px;

    .brand{
        background-color: map-get($map: $paleta, $key: secondary);
        @include flex(column, center, center, nowrap);
        color: white;
        height: 5vh;
        h2{
            margin: 0px;
            font-size: 1.5em;
        }
    }
    .accGrid{
        display: grid;
        grid-template-columns: repeat(4,1fr);
        grid-template-rows: auto;
        grid-gap: 1rem;
        text-align: center;
        padding: 15px;
        background-color: white;
    }
    #customBoxContainer-item1, #customBoxContainer-item2, #customBoxContainer-item3{
        position: relative;
        margin-bottom: 20px;
        box-shadow: 1px 0px 0px #581c4172, 0px 1px 0px #581c4172, 2px 1px 0px #581c4172, 1px 2px 0px #581c4172, 3px 2px 0px #581c4172, 2px 3px 0px #581c4172, 4px 3px 0px #581c4172, 3px 4px 0px #581c4172, 5px 4px 0px #581c4172, 4px 5px 0px #581c4172, 6px 5px 0px #581c4172, 5px 6px 0px #581c4172, 7px 6px 0px #581c4172, 6px 7px 0px #581c4172, 8px 7px 0px #581c4172, 7px 8px 0px #581c4172, 9px 8px 0px #581c4172, 8px 9px 0px #581c4172, 10px 9px 0px #581c4172, 9px 10px 0px #581c4172, 11px 10px 0px #581c4172, 10px 11px 0px #581c4172, 12px 11px 0px #581c4172, 11px 12px 0px #581c4172, 13px 12px 0px #581c4172;
    }
    .selectedCard{
        background-color: #ffe9b9;
    }
}
// #endregion

// #region loginAndRegister
.delicate-form {
    max-width: 400px;
    margin: 25px auto;
    padding: 20px;
    border-radius: 10px;
    background-color: #eed9b2;
    h2{
        color: map-get($map: $paleta, $key: evenDarkerSecondary)
    }

    .form-group {
        margin-bottom: 15px;

        label {
            margin-bottom: 5px;
            font-weight: bold;
            color: map-get($map: $paleta, $key: secondary);
        }

        input {
            width: 100%;
            padding: 10px;
            border: 1px solid map-get($map: $paleta, $key: secondary);
            border-radius: 5px;
            background-color: #fff;
            color: map-get($map: $paleta, $key: secondary);
        }
    }
    .checkbox-group{
        @include flex(row, center, center, nowrap);
        margin-bottom: 10px;
        label {
            margin-left: 10px;
            font-weight: bold;
            color: map-get($map: $paleta, $key: secondary);
        }
        input{
            width: 20px;
            height: 20px;
        }
    }
    .form-group input::placeholder {
        color: map-get($map: $paleta, $key: secondary);
    }
}

#loginReady{
    border-radius: 2px;
    background-color: map-get($map: $paleta, $key: lighterGreenLeave);
    padding: 5px;
    text-align: center;
    margin-top: 10px;
    h3{
        margin: auto;
    }
}
// #endregion

// #region profile
#profileContainer{
    background-color: #eed9b2;
    margin: 35px;
    padding: 10px;
    border: 1px solid map-get($map: $paleta, $key: evenDarkerSecondary);
    border-radius: 10px;

    h3{
        font-size: 1.5em;
        color: map-get($map: $paleta, $key: secondary);
        margin: 0px;
    }
    h2{
        font-size: 2.2em;
    }
    p{
        margin: 0px;
        font-size: 2em;
    }
}

// #endregion

// #region cart

#cartContainer{
    @extend #profileContainer;
}

.longCard{
    @include flex(row, center, center, nowrap);
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    border-radius: 10px;
    background-color: white;
    padding: 10px;
    margin: 10px;
    h3{
        font-size: 1.2em !important;
    }

    #firstHalf{
        @include flex(row, flex-start, center, nowrap);
        img{
            width: 100px;
        }
        width: 60%;
    }
    #secondHalf{
        @include flex(row, space-around, center, nowrap);
        div{
            text-align: center;
        }
        width: 40%;
    }
}

.carritoVacio{
    @include flex(column, center, center, nowrap);
    margin: 150px 0px 400px 0px;
}

#formPedido{
    @extend #profileContainer;

    form{
        @include flex(row, flex-start, flex-start, nowrap);
        input{
            margin: 2px 0px;
        }
    }
    #nombreCorreo{
        @include flex(column, center, center, nowrap);
    }
    #radiosRetiro{
        margin: 0px 15px;
        div{
            @include flex(row, center, center, nowrap);
            margin: 5px 0px;
        }
    }
    #envRet{
        @include flex(column, center, center, nowrap);
    }
    #divBoton{
        margin: auto 0px auto auto;
    }
}

// #endregion

// #region FloatingButtons
#btnWhatsapp{
    background-color: green;
    position: fixed;
    right: 15px;
    bottom: 30%;
    border-radius: 15%;
    z-index: 999;
    img{
        padding: 5px;
        width: 70px;
    }
}

#helpButton{
    z-index: 99999;
    opacity: 50%;
}

#helpButton:hover{
    opacity: 100%;
}

#btnRelax{
    background-color: #90bdbc;
    position: fixed;
    right: 15px;
    bottom: -50px;
    z-index: 999;
    border-radius: 20%;
    padding: 10px;
    transition: 0.5s;

    img{
        width: 180px;
    }

    .switch {
        display: inline-block;
        height: 34px;
        position: relative;
        width: 100px;
        margin: auto;
    
        input {
          display:none;
        }
    }

    .slider {
        background-color: map-get($map: $paleta, $key: main);
        bottom: 0;
        cursor: pointer;
        left: 0;
        position: absolute;
        right: 0;
        top: 0;
        transition: .4s;
    }
    
    .slider:before {
        background-color: map-get($map: $paleta, $key: secondary);
        bottom: 4px;
        content: "";
        height: 26px;
        left: 4px;
        position: absolute;
        transition: .4s;
        width: 26px;
    }
    
    input:checked + .slider:before {
        transform: translateX(64px);
        background-color: map-get($map: $paleta, $key: relax);
    }

    input:checked + .navbar{
        background-color: map-get($map: $paleta, $key: darkerRelax) !important;
    }
    
    .slider.round {
        border-radius: 34px;
    }
    
    .slider.round:before {
        border-radius: 50%;
    }
}

#btnRelax:hover{
    transform: translateY(-42px);
}

#liquidTransition {
    width: 100vw; /* El 100% del ancho de la ventana */
    height: 100vh; /* El 100% de la altura de la ventana */
    position: fixed; /* Se mantiene fija en la ventana */
    top: 0; /* Está en la parte superior */
    left: 0; /* Está en el lado izquierdo */
    z-index: 99999999; /* Ajusta la superposición */
}

// #endregion

// #region Relax
#relaxSite{
    margin: 15px;
    @include flex(row, flex-start, center, nowrap);
    img{
        width: 50vw;
    }

    #relaxSiteButtons{
        @include flex(column, center, center, nowrap);
        img{
            width: 300px;
        }
        div{
            width: 100%;
            padding: 0px 25px;
            h3{
                font-family: $relaxFont;
                font-size: 2rem;
                text-align: center;
                color: white;
            }
            img{
                width: 100px;
                display: block;
                margin: auto;
            }
        }
        background-color: map-get($map: $paleta, $key: relax);
        width: 100%;
        height: 100%;
        padding: 10px;
        border: 1px solid map-get($map: $paleta, $key: evenDarkerRelax);
        border-radius: 10px;
    }

    .relaxButton{
        @extend .buttn;
        background-color: #76bdbc;
        color: white;
        width: 100%;
        height: 10vh;
        font-size: 2.5em;
    }
    
    .relaxButton:hover{
        background-color: #91dad8;
        color: white;
    }
}
// #endregion